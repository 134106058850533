import React from 'react';
import '../../css_components/about_section_css/the_Team.css';

const TheTeam = () => {
    return (
        <div className="the-team-section">
            <div className="the-team-content">
                <h2>The team</h2>
                <div className="team-member">
                    <div className="image image-1"></div>
                    <div className="text-content">
                        <h3>Steve Holdych</h3>
                        <h4>Founder/Chairman</h4>
                        <p>
                            Steve Holdych brings 30+ years of experience innovating enterprise digital AI solutions that drive real business impact. But you won’t catch this University of Chicago math/econ alum getting caught up in abstract theories. As the founder of North Pass Advisors, Steve blends creative ingenuity and analytical rigor with a refreshingly down-to-earth, pragmatic perspective.
                        </p>
                    </div>
                </div>
                <div className="team-member reverse">
                    <div className="image image-2"></div>
                    <div className="text-content">
                        <h3>Darren Foot</h3>
                        <h4>Founder/CEO</h4>
                        <p>
                            Ask anyone who’s worked with Darren Foot – this dynamic marketer has an unparalleled knack for inspiring teams to develop marketing breakthroughs that don’t just grab attention, but compel action. From his early days at elite agencies to propelling renowned brands, Darren’s collaborative, open, front-footed approach helps unlock true business value with creativity and innovation.
                        </p>
                    </div>
                </div>
                <div className="team-member">
                    <div className="image image-3"></div>
                    <div className="text-content">
                        <h3>Andrew Bailey</h3>
                        <h4>Creative Director</h4>
                        <p>
                            With a creative energy that’s one part passion, one part wizardry, Andrew Bailey has been channeling his talents into crafting unforgettable digital and traditional brand experiences for over 14 years. This former Martin Agency and West Cary Group pro excels at unifying teams to launch inspiring creative ideas that transcend channels.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TheTeam;