import React from "react";
import "../../css_components/homepage_css/section_2.css";

export const Section_2 = () => {
    return (
        <div className="section-2-background">
            <div className="section-2-content">
                <h2 className="section-2-number">02</h2>
                <h2 className="section-2-header">Brand articulation + creative concepting </h2>
                <p className="section-2-body">
                We fast-track the creation and refinement of marketing and brand strategies through rapid prototyping and iterative design. By exploring creative territories and testing proof-of-concept executions, we develop high-impact go-to-market plans. Our collaborative approach facilitates agile feedback and swift adaptation to maximize results.
                </p>
            </div>
        </div>
    );
};

export default Section_2;
