import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "../css_components/Header.css";
import logo from "../assets/aveniq_dark.png";
import menuIconWhite from "../assets/menu_stack_white.png"; // White menu icon
import menuIconBlack from "../assets/menu_stack_dark.png"; // Black menu icon

// Debounce function to limit the rate of function calls
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const Header = ({ logoImage, textColor, backgroundColor }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuIcon, setMenuIcon] = useState(menuIconWhite); // State for menu icon color

  const location = useLocation(); // Get current location

  const linkStyle = {
    color: textColor || "var(--white)",
  };

  const headerStyle = {
    backgroundColor: backgroundColor || "transparent",
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      const currentScrollPos = window.pageYOffset;
      const visible = currentScrollPos < lastScrollPos || currentScrollPos < 10;
      setIsVisible(visible);
      setLastScrollPos(currentScrollPos);
    }, 100);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollPos]);

  useEffect(() => {
    // Change the menu icon color based on the current path
    if (location.pathname === "/") {
      setMenuIcon(menuIconWhite);
    } else {
      setMenuIcon(menuIconBlack);
    }
  }, [location.pathname]);

  return (
    <header className={`header ${isVisible ? "visible" : "hidden"}`} style={headerStyle}>
      <div className="logo">
        <Link to="/">
          <img src={logoImage || logo} alt="Logo" />
        </Link>
      </div>
      <div className="nav-wrapper">
        <nav className={`nav ${isMenuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <Link to="/projects" style={linkStyle}>
                Projects
              </Link>
            </li>
            <li>
              <Link to="/about-us" style={linkStyle}>
                About Us
              </Link>
            </li>
          </ul>
        </nav>
        <div className="menu-icon" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <img src={menuIcon} alt="Menu" />
        </div>
      </div>
    </header>
  );
};

export default Header;
