import React from "react";
import "../../css_components/projects_css/ProjectsSection.css";
import ProjectItem from "./ProjectItem";

const ProjectsSection = () => {
  return (
    <>
      <div className="projects-section">
        <h3>Projects</h3>
        <h2 className="headline">
          <span>Real</span>
          <div className="man-photo"></div>
          <span>results.</span>
        </h2>
        <h2 className="headline">
          <span>coming</span>
          <div className="city-photo"></div>
        </h2>
        <h2 className="headline">
          <div className="woman-photo"></div>
          <span>real soon</span>
        </h2>
        <p>
          We're currently collaborating with clients on pilot projects to
          fine-tune our human-AI approach. While we can't share specific results
          just yet, get ready for stories of our transformational approach.
        </p>
        <p>
          In the meantime,{" "}
          <a
            href="https://dub.sh/wp062424"
            target="_blank"
            rel="noopener noreferrer"
            className="redline"
          >
            dive into our whitepaper
          </a>
          , where we explore the vast potential of human-AI collaboration in
          marketing.
        </p>
        <p>Onwards.</p>
      </div>
      <ProjectItem />
    </>
  );
};

export default ProjectsSection;
