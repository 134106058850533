import React from 'react';
import '../../css_components/projects_css/ProjectItem.css';

const ProjectItem = () => {
    return (
        <div className="project-wrapper">
            <div className="project">
                <div className="project-content">
                    <h2>Let's talk</h2>
                    <p>
                        We're selectively partnering with forward-thinking brands as we refine our model.<br></br> Tell us what you're interested in, and we'll be in touch as new opportunities open up.
                    </p>
                </div>
            </div>
            <a href="mailto:hello@aveniq.co?subject=Get%20in%20Touch&body=Hello," className="contact-button-link">
                <button className="contact-button">Get in touch</button>
            </a>
        </div>
    );
};

export default ProjectItem;