import React from 'react';
import "../../css_components/homepage_css/SectionService.css";
import StickyNote from '../../assets/whatwedo1.png';
import StickyNoteMobile from '../../assets/whatwedo_mobile_1.png';
import drawing_image from '../../assets/whatwedo2.png';
import mobile_drawing_image from '../../assets/whatwedo_mobile_2.png';

const SectionService = () => {
  const heading = 'What we do';
  const subheading = 'Marketing + brand strategy. Brand articulation, creative development + planning. Creative asset development + activation.';
  // const buttonText = 'Services';

  return (
    <div className="section-service">
      <div className="content">
        <div className="heading-instance">
          <h2 className="design-component-instance-node">{heading}</h2>
          <p className="img">{subheading}</p>
          {/* <Link to="/projects">
            <button className="button-instance">
              {buttonText}
            </button>
          </Link> */}
        </div>
      </div>
      <div className="image">
        <div className="overlap-group">
          <picture>
            <source media="(max-width: 480px)" srcSet={mobile_drawing_image} />
            <source media="(max-width: 768px)" srcSet={mobile_drawing_image} />
            <img className="image-2" src={drawing_image} alt="Image 2" />
          </picture>
          <picture>
            <source media="(max-width: 480px)" srcSet={StickyNoteMobile} />
            <source media="(max-width: 768px)" srcSet={StickyNoteMobile} />
            <img className="image-3" src={StickyNote} alt="Image 3" />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default SectionService;
