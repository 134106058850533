import React from "react";
import "../../css_components/homepage_css/section_4.css";

export const Section_4 = () => {
    return (
        <div className="section-4-background">
            <div className="section-4-content">
                <h2 className="section-4-number">04</h2>
                <h2 className="section-4-header">Workshops</h2>
                <p className="section-4-body">
                    Specialized services for advanced Human-AI collaboration techniques for deeper engagement, outcomes and education.
                </p>
            </div>
        </div>
    );
};

export default Section_4;
