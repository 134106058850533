import React, { useState } from "react";
import "../../css_components/homepage_css/homepage.css";

const Homepage = () => {

  return (
    <div id="home" className="section homepage">
      <div className="fixed-line left"></div>
      <div className="fixed-line right"></div>

      <div className="headline-container">
        <h1 className="headline">A New Species of Agency</h1>
        <p className="headline-description">
        One that combines the creativity of the human mind with the power of AI to inspire groundbreaking ideas and unlock greater outcomes.
        </p>
      </div>
    </div>
  );
};

export default Homepage;
