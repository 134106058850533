import React from 'react';
import '../../css_components/about_section_css/Who_we_are.css';

const WhoWeAre = () => {
    return (
        <div className="who-we-are-section">
            <div className="who-we-are-content">
                <h2>Who We Are</h2>
                <p>
                    In an era where artificial intelligence is revolutionizing business and knowledge work, Aveniq emerged as a
                    pioneering force – a new species of marketing agency that harmoniously fuses human creativity and
                    technological prowess into its very DNA. Founded by Darren Foot and Steve Holdych, Aveniq is poised to redefine the industry.
                </p>
                <p>
                    We embrace AI as an accelerant while guiding it with human insight. This approach frees up human
                    capacity and unlocks rapid iteration, customization and scale, while ensuring our solutions remain
                    grounded in emotional resonance and warmth. Driven by curiosity and a quest to redefine possibilities, we offer client partners inspired ideas powered by
                    seamless human-AI collaboration.
                </p>
            </div>
        </div>
    );
};

export default WhoWeAre;
