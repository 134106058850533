import React from "react";
import "../../css_components/about_section_css/AboutUsSection.css";
import WhoWeAre from "./Who_we_are";
import TheTeam from "./the_Team";

const AboutUsSection = () => {
  return (
    <>
      <div className="about-us-section">
      </div>
      <WhoWeAre />
      <TheTeam />
    </>
  );
};

export default AboutUsSection;
