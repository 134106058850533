import React from "react";
import "../../css_components/homepage_css/AboutUs.css";
import { Link } from 'react-router-dom';

const AboutUs = () => {
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="about-us">
      <h2 className="headline">
        <span>Resonate</span>
        <div className="man-photo"></div>
        <span>deeper.</span>
      </h2>
      <h2 className="headline">
        <div className="city-photo"></div>
        <span>Move faster.</span>
      </h2>
      <h2 className="headline">
        <span>Get</span>
        <div className="woman-photo"></div>
        <span>results.</span>
      </h2>
      <p>
        Evolving marketing with human-AI collaboration for unmatched emotional
        resonance, speed to market and significant enterprise impact.
      </p>
      <Link to="/about-us">
        <button className="about-button" onClick={handleScrollToTop}>About us</button>
      </Link>
    </section>
  );
};

export default AboutUs;
