import React from "react";
import { Link } from "react-router-dom";
import "../css_components/Footer.css";
import logo from "../assets/aveniq_mark_white 1.png";
import privacyPolicy from "../assets/website_privacy_policy_tos_6-24-24.pdf";
import tos from "../assets/website_tos_6-24-24.pdf";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo-section">
          <img src={logo} alt="Logo" className="footer-logo" />
          <div className="footer-tagline">Accelerating human ingenuity.</div>
        </div>
        <nav className="footer-nav">
          <ul>
            <li>
              <Link to="/" onClick={scrollToTop}>
                Home
              </Link>
            </li>
            {/* <li>
                            <Link to="/services">Services</Link>
                        </li> */}
            <li>
              <Link to="/projects" onClick={scrollToTop}>
                Projects
              </Link>
            </li>
            <li>
              <Link to="/about-us" onClick={scrollToTop}>
                About Us
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer-bottom-section">
        <div className="footer-privacy">
          <a href={privacyPolicy} target="_blank" rel="noopener noreferrer">
            Privacy policy
          </a>
          <a href={tos} target="_blank" rel="noopener noreferrer">
            Terms of service
          </a>
        </div>
        <div className="footer-copyright">
          <span className="footer-copyright-desktop">© 2024 Aveniq</span>
          <span className="footer-copyright-mobile">@ 2024</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
