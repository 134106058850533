import React, { useState, useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
} from "react-router-dom";
import Header from "./components/Header";
import Home from "./components/homepage/homepage";
import About from "./components/homepage/AboutUs";
import SectionService from "./components/homepage/SectionService";
import Footer from "./components/Footer";
import SectionCard from "./components/homepage/SectionCard";
import "./App.css";
import Section_2 from "./components/homepage/Section_2";
import Section_3 from "./components/homepage/Section_3";
import Section_4 from "./components/homepage/Section_4";
import { SpeedInsights } from "@vercel/speed-insights/react";
import AboutUsSection from "./components/about_us_section/AboutUsSection";
import ProjectsSection from "./components/projects/ProjectsSection";
import lightLogo from "./assets/aveniq_logo_white.png"; // Import the light logo image
import darkLogo from "./assets/aveniq_dark.png"; // Import the dark logo image

const AppContent = () => {
    const location = useLocation();
    const isHomePage = location.pathname === "/";
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Check on initial load

        window.addEventListener("resize", handleResize); // Add event listener for window resize

        return () => {
            window.removeEventListener("resize", handleResize); // Clean up the event listener on component unmount
        };
    }, []);

    return (
        <div className="App">
            <Header
                textColor={isHomePage ? "#ffffff" : "#2D3047"}
                backgroundColor={isHomePage ? "transparent" : "white"}
                logoImage={isHomePage ?  lightLogo : darkLogo} // Pass the appropriate logo image based on the current page
                isMobile={isMobile}
            />
            <Routes>
                <Route
                    exact
                    path="/"
                    element={
                        <>
                            <Home />
                            <About />
                            <SectionService />
                            <SectionCard />
                            <Section_2 />
                            <Section_3 />
                            <Section_4 />
                        </>
                    }
                />
                <Route path="/about-us" element={<AboutUsSection />} />
                <Route path="/projects" element={<ProjectsSection />} />
            </Routes>
            <Footer />
            <SpeedInsights />
        </div>
    );
};

const App = () => (
    <Router>
        <AppContent />
    </Router>
);

export default App;
