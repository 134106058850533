import React from "react";
import "../../css_components/homepage_css/SectionCard.css";


export const SectionCard = () => {
    return (
        <div className="section-card">
            <div className="section-card-content">
                <h2 className="section-card-number">01</h2>
                <h2 className="section-card-header">Marketing + Brand Strategy</h2>
                <p className="section-card-body">
                    We develop accelerated, expansive marketing strategies by combining human ingenuity with AI-driven data analysis to uncover compelling insights. Our forward-thinking approach helps us anticipate market trends, future-proof your brand, and unlock new growth opportunities.
                </p>
            </div>
        </div>
    );
};

export default SectionCard;
