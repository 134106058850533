import React from "react";
import "../../css_components/homepage_css/section_3.css";

export const Section_3 = () => {
    return (
        <div className="section-3-background">
            <div className="section-3-content">
                <h2 className="section-3-number">03</h2>
                <h2 className="section-3-header">Creative asset development + activation</h2>
                <p className="section-3-body">
                Our human-led, technology-powered approach enables swift development of high-volume, quality creative assets across all channels and formats. By harnessing the strengths of both human creativity and AI capabilities, we deliver the scale and personalization modern marketing demands.
                </p>
            </div>
        </div>
    );
};

export default Section_3;
